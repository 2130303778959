import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import SelectDateSplitted from "../../../../components/SelectDateSplitted/index.jsx";
import Select from "../../../../components/Select/index.jsx";

function GeneralOptinForm({ customerData, changeCustomerData, leadExistedData, invalidPhone, errorMsg, setCustomerData }) {
	console.log("customerData", customerData)
	return (
	  <>
		  {leadExistedData?.name && <p>{`Olá ${leadExistedData?.name.split(" ")[0]}, confirme seus dados:`} </p>}
		  <div className="general-optin-form-container">
			  <p className="gs-hotlead-label">Nome</p>
			  <input
				  name="name"
				  data-testid="name-input"
				  placeholder="Seu nome"
				  className="default-input"
				  value={customerData.name}
				  onChange={(e) => changeCustomerData(e)}
			  />
			  <p className="gs-hotlead-label">Celular</p>
			  <InputMask
				  name="phone"
				  id="phone-input"
				  data-testid="phone-input"
				  className={`default-input ${
					  invalidPhone ? "input-required-invalid" : ""
				  }`}
				  placeholder="Seu celular"
				  value={customerData.phone}
				  onChange={(e) => changeCustomerData(e)}
				  mask="(99) 99999-9999"
			  />
			  <Select
				  label="Gênero"
				  name="gender"
				  value={customerData.gender}
				  onChange={(e) => changeCustomerData(e)}
				  style={{borderColor: "rgb(118, 118, 118)", height: "42px", borderRadius: "8px", fontSize: "14px"}}
				  options={[
					  { name: "Selecionar", value: "" },
					  { name: "Masculino", value: "Masculino" },
					  { name: "Feminino", value: "Feminino" },
					  { name: "Outro", value: "Outro" },
					  { name: "Prefiro não informar", value: "Nao_informado" }
				  ]}
			  />
			  <p className="gs-hotlead-label">Qual sua data de nascimento?</p>
			  <SelectDateSplitted
				  selectedDate={customerData.bornAt}
				  onDateChange={(date) => {
					  setCustomerData((old) => ({...old, bornAt: date}));
				  }}
			  />
			  {errorMsg && <span className="label-error text-center anime-scale-in-center">{`Ops, ${errorMsg}...`}</span>}
		  </div>
	  </>
  )
}

GeneralOptinForm.propTypes = {
	changeCustomerData: PropTypes.func.isRequired,
	invalidPhone: PropTypes.bool.isRequired,
	errorMsg: PropTypes.string.isRequired,
	customerData: PropTypes.object.isRequired,
	leadExistedData: PropTypes.object.isRequired,
};

export default GeneralOptinForm;
