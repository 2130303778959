import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";


function BrandsList({className="", ...rest}) {
  return (
    <ul className={`gs-brands-list-container ${className}`} {...rest} />
  );
}

BrandsList.propTypes = {
  className: PropTypes.string,
};

export default BrandsList;
