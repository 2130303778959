import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {checkGif} from "../../../../assets/imgs/index.js";
import {external} from "../../../../services/linkHelper.js";


function GeneralSucessPage({
 cupomImg,
 buttons,
 logo,
 loading,
 location,
 params,
 customerData,
 groupBusinessId,
 hasValidOffer,
 customerName=""
}) {
	const navigate = useNavigate();
  return (
	  <div className="welcome-page">
		  <div className="welcome-success">
			  <div>
				  <img
					  alt="business logo"
					  className="align-start email-business-img business-logo-img"
					  src={checkGif}
					  width={"100%"}
					  height={160}
				  />
			  </div>
			  {customerName ? (
				  <>
					  <h1>{`Parabéns, ${customerName}!`}</h1>
					  <p className="general-success-optin-msg">
						  Em instantes você poderá receber ofertas exclusivas das suas marcas favoritas!
					  </p>
				  </>
			  ) : (
				  <>
					  <h3>Cpf encontrado!</h3>
					  <p className="general-success-optin-msg">
						  Seu cpf já consta em nossa base de dados, então a qualquer momento você poderá receber ofertas exclusivas das suas marcas favoritas!
					  </p>
				  </>
			  )}
			  {cupomImg && location.state.isNewOptIn && (
				  <div className="div-image-cupom">
					  <img
						  className="image-cupom business-logo-img"
						  alt="Cupom promocional"
						  src={cupomImg}
					  />
				  </div>
			  )}

			  {groupBusinessId !== 1463 && hasValidOffer && (
				  <div
					  className="btn btn-welcome btn-welcome-link link-not-decored"
					  onClick={() => {
						  navigate(`${external.OFFERS}/${params.businessId}`, {
							  state: {
								  cpf: customerData,
								  groupBusinessId: groupBusinessId,
							  },
						  });
					  }}
				  >
					  Ver ofertas
				  </div>
			  )}

			  {buttons?.map((buttonData, index) => (
				  <div key={`btn-welcome-${index}`} className="welcome-right">
					  <form className="form optin">
						  <a
							  key={`button-page-${index}-31`}
							  className="btn btn-welcome btn-welcome-link link-not-decored"
							  href={buttonData.url}
							  target="_blank"
							  rel="noopener noreferrer"
						  >
							  {buttonData.label}
						  </a>
					  </form>
				  </div>
			  ))}
		  </div>
	  </div>
  );
}

GeneralSucessPage.propTypes = {
	cupomImg: PropTypes.string,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			url: PropTypes.string,
		})
	),
	logo: PropTypes.string,
	loading: PropTypes.bool,
	location: PropTypes.shape({
		state: PropTypes.shape({
			isNewOptIn: PropTypes.bool,
		}),
	}),
	params: PropTypes.object,
	customerData: PropTypes.string,
	groupBusinessId: PropTypes.number,
	hasValidOffer: PropTypes.bool,
	customerName: PropTypes.string,
};

export default GeneralSucessPage;
