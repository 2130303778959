import Logo from "./Logo";
import CpfForm from './CpfForm';
import DefaultOptinForm from "./DefaultOptinForm";
import DefaultTermsOfUse from "./DefaultTermsOfUse";
import SuccessSkeleton from "./Skeletons/SuccessSkeleton.jsx";
import WelcomeSkeleton from "./Skeletons/WelcomeSkeleton.jsx";
import WelcomeLeftSkeleton from "./Skeletons/WelcomeLeftSkeleton.jsx";

export default {
	CpfForm,
	DefaultOptinForm,
	DefaultTermsOfUse,
	Logo,
	SuccessSkeleton,
	WelcomeSkeleton,
	WelcomeLeftSkeleton
}