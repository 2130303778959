import PropTypes from "prop-types";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";
import imgFood from "../../../assets/imgs/nao_disponivel.jpg";
import OffersToCustomer from "../../../components/Offers/OffersToCustomer";
import { getOffersByBusinessId } from "../../../graphql/Query/offers";
import "./styles.css";

export default function OfferList({ businessId }) {
  const [offers, setOffers] = useState([]);


  useEffect(() => {
    const loadOffers = async () => {
      try {
        const offers = await getOffersByBusinessId(parseInt(businessId));
        const filteredOffers = offers.filter(
          (offer) =>
            moment(`${offer.fromDate} 00:00:00`, "DD/MM/YYYY hh:mm:ss") <= moment() &&
            moment(`${offer.toDate} 23:59:59`, "DD/MM/YYYY hh:mm:ss") >= moment()
        );
        setOffers(filteredOffers);
      } catch (e) {
        return imgFood;
      }
    };

    loadOffers();
  }, []);

  const offerMaxDay = useMemo(() =>
    offers.length > 0
      ? offers.reduce((acc, curr) => curr.toDate > acc ? curr.toDate : acc ,moment().format("DD/MM/YYYY"))
      : "",[offers]);

  return (
    <>
      <div className="offer-list">
        {offers?.map((offer) => (
          <div key={crypto.randomUUID()} className="offer-title">
            <h2>{offer.title}</h2>
            <div
              className={
                offers.length === 1 ? "offer-list-wrap" : "offer-carousel"
              }
            >
              {offer.products?.map((product) => (
                <OffersToCustomer
                  key={crypto.randomUUID()}
                  image={product.image}
                  fromPrice={product.fromPrice}
                  toPrice={product.toPrice}
                  description={product.productGroup.description}
                />
              ))}
              <div className="space-x" />
            </div>
          </div>
        ))}
      </div>
      {offerMaxDay && (
        <footer className="footer-offer-warning">
          <p className="footer-offer-warning-1">{`Ofertas válidas até ${offerMaxDay}, podendo ser suspensa ou alterada sem aviso prévio.`} </p>
          <p className="footer-offer-warning-2">Não cumulativo com outras promoções.</p>
        </footer>
      )}
    </>
  );
}

OfferList.propTypes = {
  businessId: PropTypes.string.isRequired,
};
