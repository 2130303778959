import {client, gql} from "../config";

export async function getListOffers(data) {
    const offersQuery = gql`
        query ($businessId: Int, $page: Int, $maxPerPage: Int, $sortBy: String, $orderBy: OrderBy){
            Offers(businessId: $businessId, page: $page, maxPerPage: $maxPerPage, sortBy: $sortBy, orderBy: $orderBy){
                data{
                    id,
                    title,
                    products {
                        product{
                            description
                            image
                        }
                    },
                    createdAt
                }
            }
        }
    `;

    const offers = await client.query({
        query: offersQuery,
        variables: data[0],
    });

    return await offers.data;
}

export async function getActivationDetail(data) {
    const activationsQuery = gql`
        query ($id: Int, $businessId: Int, $dateFrom: DateTime, $dateTo: DateTime, $page: Int, $maxPerPage: Int, $sortBy: String, $orderBy: OrderBy){
            Activations(id: $id, businessId: $businessId, dateFrom: $dateFrom, dateTo: $dateTo, page: $page, maxPerPage: $maxPerPage, sortBy: $sortBy, orderBy: $orderBy){
                data{
                    id,
                    name,
                    description,
                    startAt,
                    endAt,
                    vehicles {
                        type
                    }
                    products {
                        id,
                        description
                    }
                    newCustomers,
                    personCount,
                    purchaseCount,
                    revenue,
                    data {
                        BEST,
                        LOYAL,
                        SPENDER,
                        NEW,
                        ALMOST_LOST,
                        LOST
                    }
                }
            }
        }
    `;

    const activations = await client.query({
        query: activationsQuery,
        variables: data[0],
    });

    return await activations.data;
}

export async function getMoreInfoActivation(data) {
    const dashboardQuery = gql
            `query(
            $businessId: Int!
            $dateTo: DateTime
            $dateFrom: DateTime
        ){
            dashboard(businessId: $businessId, dateTo: $dateTo, dateFrom: $dateFrom){
                totalRevenue
                salesMadeCount
                newCustomerCount{
                    key
                    value
                }
            }
        }`
    const dashboard = await
        client
            .query({
                query: dashboardQuery,
                variables: data[0]
            });

    return await dashboard.data;
}

export async function getOfferById(data) {
    const offersQuery = gql`
        query ($id: Int!, $businessId: Int, $page: Int, $maxPerPage: Int, $sortBy: String, $orderBy: OrderBy){
            Offers(id: $id, businessId: $businessId, page: $page, maxPerPage: $maxPerPage, sortBy: $sortBy, orderBy: $orderBy){
                data{
                    id,
                    title,
                    toDate,
                    fromDate,
                    products {
                        fromPrice,
                        toPrice,
                        image
                        product{
                            id
                            description
                        },
                        data{
                            views,
                            sales_count,
                            conversion_rate,
                            total_revenue
                        }
                    },
                    createdAt
                }
            }
        }
    `;

    const offers = await client.query({
        query: offersQuery,
        variables: data[0],
    });

    return await offers.data;
}

export async function getOffersByBusinessId(businessId) {
    const getActivation = gql`
        query ($businessId: Int!) {
            Offers(businessId: $businessId) {
                data {
                    id
                    title
                    fromDate
                    toDate
                    products {
                        productGroup {
                            description
                            products {
                                image
                            }
                        }
                        fromPrice
                        toPrice
                        image
                    }
                }
            }
        }
    `;

    const {
        data: {
            Offers: {data},
        },
    } = await client.query({
        query: getActivation,
        variables: {
            businessId,
        },
        fetchPolicy: "no-cache",
    });

    return data;
}

export async function getProductsByCustomerCpf(businessGroupId, cpf) {
    const getProducts = gql`
        query ($businessGroupId: Int!, $cpf: String!){
            CustomersFavoriteProducts(businessGroupId: $businessGroupId, cpf: $cpf){
                customersFavoriteProducts {
                    product_id
                    image
                    description
                    value
                    amount_purcharses
                }
            }
        }
    `;

    const products = await client.query({
        query: getProducts,
        variables: {
            businessGroupId,
            cpf
        },
        fetchPolicy: 'no-cache'
    });

    return await products.data.CustomersFavoriteProducts;
}

export async function getProductGroupIdByProduct(id) {
    const getProductGroupId = gql`
        query ($id: Int){
            Products(id: $id){
                data{
                    productGroup{
                        id
                    }
                }
            }
        }
    `;

    const products = await client.query({
        query: getProductGroupId,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    });

    return await products.data;
}

