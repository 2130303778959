import CpfForm from './CpfForm';
import GeneralOptinForm from "./GeneralOptinForm";
import GeneralTermsOfUse from "./GeneralTermsOfUse";
import PromotionDisabled from "./PromotionDisabled";

export default {
	CpfForm,
	GeneralOptinForm,
	GeneralTermsOfUse,
	PromotionDisabled
}