import React, { useRef } from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import SearchInput from "../../../../components/SearchInput/index.jsx";


function BrandSearch({ setSearch, placeHolder= "Faça uma busca" }) {
  const searchRef = useRef(null);

  return (
    <section className="gs-brand-search-container">
      <SearchInput
        ref={searchRef}
        setState={setSearch}
        placeholder={placeHolder}
        className={"search-icon-black"}
        id={"search-business-activation"}
        data-testid="search-segments-input"
      />
    </section>
  );
}

BrandSearch.propTypes = {
  setSearch: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
};

export default BrandSearch;
