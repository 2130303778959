import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { FormHelperText } from "@mui/material";
import SelectDateSplitted from "../../../../components/SelectDateSplitted/index.jsx";


function DefaultOptinForm({ changeCustomerData, invalidPhone, setCustomerData, customerData }) {
  return (
	  <>
		  <p className="welcome-form-label margin-top-0">
			  Como gostaria de ser chamado? (*)
		  </p>
		  <input
			  name="name"
			  placeholder="Seu nome"
			  className="default-input"
			  onChange={(e) => changeCustomerData(e)}
		  />

		  <p className="welcome-form-label">Qual o seu e-mail?</p>
		  <input
			  name="email"
			  type="email"
			  placeholder="Seu email"
			  className="default-input"
			  onChange={(e) => changeCustomerData(e)}
		  />

		  <p className="welcome-form-label">Qual o seu celular? (*)</p>
		  <InputMask
			  name="phone"
			  className={`default-input ${
				  invalidPhone ? "input-required-invalid" : ""
			  }`}
			  placeholder="(61) 99999-9999"
			  onChange={(e) => changeCustomerData(e)}
			  mask="(99) 99999-9999"
		  />
		  {invalidPhone && (
			  <FormHelperText
				  error={true}
				  className="font-medium"
				  style={{ fontFamily: "Outfit, sans-serif" }}
			  >
				  Número inválido
			  </FormHelperText>
		  )}
		  <p className="welcome-form-label">Qual sua data de nascimento?</p>
		  <SelectDateSplitted
			  selectedDate={customerData.bornAt}
			  onDateChange={(date) => {
				  setCustomerData((old) => ({...old, bornAt: date}));
			  }}
		  />
	  </>
  );
}

DefaultOptinForm.propTypes = {
	changeCustomerData: PropTypes.func.isRequired,
	invalidPhone: PropTypes.bool.isRequired,
	setCustomerData: PropTypes.func.isRequired,
	customerData: PropTypes.object.isRequired,
}

export default DefaultOptinForm;
