import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./styles.css";
import PropTypes from "prop-types";

const SearchInput = forwardRef(({ className = "", setState, ...rest }, ref) => {
  const timeoutRef = useRef(null);
  const [inputLoading, setInputLoading] = useState("");

  const setStateDelayed = (newState, delay, callback) => {
    if (delay === 0 || delay === undefined) {
      setState(newState);
    } else {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        if (callback) callback();
        setState(newState);
        timeoutRef.current = null;
      }, delay);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const clearStats = () => setInputLoading("");

  return (
    <input
      ref={ref}
      type="text"
      placeholder="Faça sua busca"
      className={`search-input-grey ${className} ${inputLoading}`}
      onChange={(e) => setStateDelayed(e.target.value, 1000, clearStats)}
      onInput={() => setInputLoading("search-input-loading")}
      {...rest}
    />
  );
});

SearchInput.propTypes = {
  className: PropTypes.string,
  setState: PropTypes.func,
};

SearchInput.displayName = "SearchInput";
export default SearchInput;
