import ApolloClient from 'apollo-boost';
export { gql } from "apollo-boost";

export const client = new ApolloClient({
  uri:  import.meta.env.VITE_API_URL,
  request: (operation) => {
    const token = localStorage.getItem('app-biud-token')

    operation.setContext({
      headers: {
        authorization: `Bearer ${token || "."}`,
      }
    })
  }
});

