import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

function BiudButton({
  id = "btn-biud-button",
  className = "",
  onClick = () => "",
  children,
  buttonType = "primary",
  size = "sm",
  bold = false,
  isCollapse = false,
  style = {},
  ...rest
}) {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      style={style}
      {...rest}
      className={`btn-${buttonType} btn-size-${size} ${!bold ? "btn-bold-false" : ""} ${
        isCollapse ? "collapse-btn" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
}

BiudButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    buttonType: PropTypes.oneOf([ "primary",
      "secondary",
      "tertiary",
      "quaternary",
      "quinary",
      "senary",
      "success",
      "warning",
      "biud",
      "disabled",
      "primary-outlined",
      "secondary-outlined",
      "tertiary-outlined",
      "quaternary-outlined",
      "quinary-outlined",
      "success-outlined",
      "alert-outlined",
      "text-primary",
      "text-secondary",
      "text-tertiary",
      "text-quaternary",
      "disabled-outlined"]).isRequired,
    size: PropTypes.oneOf(["sm", "md", "lg"]),
    bold: PropTypes.bool,
    isCollapse: PropTypes.bool,
    style: PropTypes.object,
};

export default BiudButton;
