import React from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { SelectDatepicker } from "react-select-datepicker";

function SelectDateSplitted({selectedDate, onDateChange}) {
  return (
	  <SelectDatepicker
		  selectedDate={selectedDate}
		  onDateChange={onDateChange}
		  className="optin-datapicker-content"
		  order={"day/month/year"}
		  hideLabels={true}
		  labels={{
			  dayLabel : "Dia",
			  monthLabel : "Mês",
			  yearLabel : "Ano",
			  dayPlaceholder : "Dia",
			  monthPlaceholder : "Mês",
			  yearPlaceholder : "Ano",
			  months : {
				  1: "Janeiro",
				  2: "Fevereiro",
				  3: "Março",
				  4: "Abril",
				  5: "Maio",
				  6: "Junho",
				  7: "Julho",
				  8: "Agosto",
				  9: "Setembro",
				  10: "Outubro",
				  11: "Novembro",
				  12: "Dezembro",
			  }
		  }}
	  />
  );
}

SelectDateSplitted.propTypes = {
	selectedDate: PropTypes.instanceOf(Date),
	onDateChange: PropTypes.func.isRequired
};

export default SelectDateSplitted;
