import React from "react";
import { Skeleton } from "@mui/material";

export default function SuccessSkeleton() {
  return (
    <div className="welcome-page">
      <div className="welcome-success">
        <Skeleton
          variant="rounded"
          animation="wave"
          width={110}
          height={110}
          style={{ marginTop: 20 }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width="75%"
          style={{ margin: 30 }}
        >
          <h1>.</h1>
        </Skeleton>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="75%"
          style={{ margin: 20 }}
        >
          <p>.</p>
        </Skeleton>

        <Skeleton
          variant="rounded"
          animation="wave"
          width="75%"
          style={{ margin: 20 }}
          height={50}
        >
          <div className="btn btn-welcome btn-welcome-link link-not-decored"></div>
        </Skeleton>

        <Skeleton
          variant="rounded"
          animation="wave"
          width="75%"
          style={{ margin: 20 }}
          height={50}
        >
          <div key={`btn-welcome`} className="welcome-right"></div>
        </Skeleton>
      </div>
    </div>
  );
}
