import React from "react";
import "./styles.scss";
import termosUso from "../../../../assets/documents/termo-de-uso.pdf";
import validarPoliticaPrivacidade from "../../../../assets/documents/politica-privacidade.pdf";


function DefaultTermsOfUse() {
  return (
	  <div className="font-medium text-center">
      <span className="color-gray-strong">
        Ao se cadastrar você concorda com nossos
      </span>
		  <br/>
		  <span className="flex align-items-center">
        <a
          className="link-not-decored button-privacy-link color-blue"
          target="_blank"
          href={termosUso}
          rel="noopener noreferrer"
        >
          Termos de uso
        </a>
        <div className="color-gray-strong margin-xxs">e</div>
        <a
          className="link-not-decored button-privacy-link color-blue"
          target="_blank"
          rel="noopener noreferrer"
          href={validarPoliticaPrivacidade}
        >
          Política de privacidade
        </a>
      </span>
	  </div>
  );
}

export default DefaultTermsOfUse;
