export const internalLink = {
  DEFAULT: "/internal/dashboard",
  DASHBOARD: "/internal/dashboard",
  BUSINESS_REGISTER: "/internal/businessregister",
  GALERY: "/internal/galery",
  ACTIVATION: "/internal/activations",
  INSIGHTS: "/internal/insights",
};

export const external = {
  OFFERS: "/offersCustomer",
  SUCCESS: "/success",
  GSSUCCESS: "/hotlead/success",
  GSDEPARTMENTS: "/hotlead/gsdepartments",
};
