import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

function Select({
  id = "",
  label = "",
  value = "",
  onChange = () => "",
  options = [{ name: "", value: "" }],
  containerWidth = "100%",
  name = "",
  className = "",
  required = false,
  disabled = false,
  ...rest
}) {
  return (
    <div id={id} className={`new-select-container ${className}`} style={{ width: containerWidth }}>
      {label && <label htmlFor="freq-finalize">{label}</label>}
      <select
        id={label}
        value={value}
        onChange={onChange}
        name={name}
        required={required}
        disabled={disabled}
        {...rest}
      >
        {options.map(({ name, value }, index) => {
          return (
            <option key={`${name}-${index}`} title={name} value={value}>
              {name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

Select.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    options: PropTypes.array,
    containerWidth: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Select;
