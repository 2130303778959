import Header from "./Header";
import BrandsList from "./BrandsList";
import BrandCard from "./BrandCard";
import Footer from "./Footer";
import BrandSearch from "./BrandSearch";

export default {
	Header,
	BrandsList,
	BrandCard,
	Footer,
	BrandSearch
}