import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import WelcomeComponents from "../index.js";
import BiudButton from "../../../../components/BiudButton/index.jsx";


function OptinForm({ registerCheck, handleFormContent, showRegisterForm, loadingForm, loading, isGeneralGroup, customerData }) {
  return (
	  <div className="form-container">
		  {handleFormContent()}
		  {showRegisterForm && (
			  <>
				  <BiudButton
					  buttonType={isGeneralGroup ? "warning" : "tertiary"}
					  size="lg"
					  className="btn"
					  onClick={() => registerCheck()}
					  disabled={loadingForm || loading}
					  style={{width: "auto", padding: "8px 16px", minWidth: "max-content", margin: "16px 0"}}
				  >
					  {!isGeneralGroup && (loadingForm ? "Salvando..." : "Quero me cadastrar")}
					  {isGeneralGroup && (loadingForm ? "Salvando..." : "Avançar")}
				  </BiudButton>
				  {isGeneralGroup ? <WelcomeComponents.GeneralTermsOfUse customerData={customerData} /> : <WelcomeComponents.DefaultTermsOfUse/>}
			  </>
		  )}
	  </div>
  );
}

OptinForm.propTypes = {
	registerCheck: PropTypes.func.isRequired,
	handleFormContent: PropTypes.func.isRequired,
	showRegisterForm: PropTypes.bool.isRequired,
	loadingForm: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	isGeneralGroup: PropTypes.bool.isRequired,
	customerData: PropTypes.object,
};

export default OptinForm;
