import { client, gql } from "../config";

export async function BusinessEnrichCustomers(
  businessId,
  CustomerClassification
) {
  const BusinessEnrichCustomers = gql`
    mutation (
      $businessId: Int!
      $customerClassification: CustomerClassification
    ) {
      BusinessEnrichCustomers(
        businessId: $businessId
        customerClassification: $customerClassification
      )
    }
  `;
  return await client.mutate({
    mutation: BusinessEnrichCustomers,
    variables: {
      businessId: businessId,
      customerClassification: CustomerClassification,
    },
    fetchPolicy: "no-cache",
  });
}

export async function RegisterCustomerOptIn(variables) {
  const mutation = gql`
    mutation (
      $businessId: Int!
      $name: String
      $cpf: String!
      $email: String
      $phone: String
      $bornAt: Date
      $registeredByCustomer: Boolean
    ) {
      RegisterCustomerOptIn(
        businessId: $businessId
        name: $name
        cpf: $cpf
        email: $email
        phone: $phone
        bornAt: $bornAt
        registeredByCustomer: $registeredByCustomer
      )
    }
  `;

  return client.mutate({
    mutation,
    variables,
    fetchPolicy: "no-cache",
  });
}

export async function RegisterCustomerOptOut(data) {
  const RegisterCustomerOptOutMutation = gql`
    mutation ($businessId: Int, $groupId: Int, $customerId: Int!, $reasonId: Int!) {
      RegisterCustomerOptOut(
        businessId: $businessId
        groupId: $groupId
        customerId: $customerId
        reasonId: $reasonId
      )
    }
  `;
  return await client.mutate({
    mutation: RegisterCustomerOptOutMutation,
    variables: {
      businessId: Number.parseInt(data.businessId),
      groupId: Number.parseInt(data.groupId),
      customerId: Number.parseInt(data.customerId),
      reasonId: Number.parseInt(data.reasonId),
    },
    fetchPolicy: "no-cache",
  });
}

export async function RegisterCustomer(data) {
  const RegisterCustomerMutation = gql`
    mutation (
      $businessId: Int!
      $name: String!
      $email: String
      $phone: String
      $isPhoneWhatsapp: Boolean
      $cpf: String!
      $bornAt: Date
      $gender: Gender
      $cep: String
      $street: String
      $cityId: Int
      $facebook: String
      $instagram: String
      $whereKnow: String
    ) {
      RegisterCustomer(
        businessId: $businessId
        name: $name
        email: $email
        phone: $phone
        isPhoneWhatsapp: $isPhoneWhatsapp
        cpf: $cpf
        bornAt: $bornAt
        gender: $gender
        cep: $cep
        street: $street
        cityId: $cityId
        facebook: $facebook
        instagram: $instagram
        whereKnow: $whereKnow
      )
    }
  `;
  return await client.mutate({
    mutation: RegisterCustomerMutation,
    variables: {
      businessId: data.businessId,
      name: data.name,
      cpf: data.cpf,
      email: data.email,
      phone: data.phone,
      isPhoneWhatsapp: data.isWhatsapp,
      bornAt: data.birthDate,
      gender: data.gender,
      cep: data.cep,
      street: data.address,
      cityId: data.cityId,
      facebook: data.facebook,
      instagram: data.instagram,
      whereKnow: data.knowUs,
    },
    fetchPolicy: "no-cache",
  });
}

export async function BusinessEnableEnrichCustomers(businessId) {
  const BusinessEnableDataEnrichment = gql`
    mutation ($businessId: Int!) {
      BusinessEnableDataEnrichment(businessId: $businessId)
    }
  `;
  return await client.mutate({
    mutation: BusinessEnableDataEnrichment,
    variables: {
      businessId: businessId,
    },
    fetchPolicy: "no-cache",
  });
}

export async function BusinessDisableEnrichCustomers(businessId) {
  const BusinessDisableDataEnrichment = gql`
    mutation ($businessId: Int!) {
      BusinessDisableDataEnrichment(businessId: $businessId)
    }
  `;
  return await client.mutate({
    mutation: BusinessDisableDataEnrichment,
    variables: {
      businessId: businessId,
    },
    fetchPolicy: "no-cache",
  });
}

export async function enrichCustomersByFilter(data) {
  const EnrichCustomersByFilter = gql`
    mutation (
      $businessId: Int!
      $classifications: [CustomerClassification]
      $name: String
      $isOptIn: Boolean
      $isOptInBusiness: Boolean
      $isOptInCustomer: Boolean
      $purchaseDateFrom: DateTime
      $purchaseDateTo: DateTime
      $purchaseValueFrom: Float
      $purchaseValueTo: Float
      $purchaseCountFrom: Int
      $purchaseCountTo: Int
      $ageFrom: Int
      $ageTo: Int
      $districts: [String]
      $genders: [Gender]
      $productIds: [Int]
      $productGroupIds: [Int]
      $purchaseHourFrom: Time
      $purchaseHourTo: Time
      $birthdayFrom: Date
      $birthdayTo: Date
      $purchaseCountGroupPeriod: Period
      $isProductsSamePurchase: Boolean
      $isOptOut: Boolean
      $dayBirthday: Boolean
      $weekBirthday: Boolean
      $email: String
      $cpf: String
    ) {
      EnrichCustomers(
        businessId: $businessId
        classifications: $classifications
        name: $name
        isOptIn: $isOptIn
        isOptInBusiness: $isOptInBusiness
        isOptInCustomer: $isOptInCustomer
        isOptOut: $isOptOut
        purchaseDateFrom: $purchaseDateFrom
        purchaseDateTo: $purchaseDateTo
        purchaseValueFrom: $purchaseValueFrom
        purchaseValueTo: $purchaseValueTo
        purchaseCountFrom: $purchaseCountFrom
        purchaseCountTo: $purchaseCountTo
        ageFrom: $ageFrom
        ageTo: $ageTo
        districts: $districts
        genders: $genders
        productIds: $productIds
        productGroupIds: $productGroupIds
        purchaseHourFrom: $purchaseHourFrom
        purchaseHourTo: $purchaseHourTo
        birthdayFrom: $birthdayFrom
        birthdayTo: $birthdayTo
        purchaseCountGroupPeriod: $purchaseCountGroupPeriod
        isProductsSamePurchase: $isProductsSamePurchase
        dayBirthday: $dayBirthday
        weekBirthday: $weekBirthday
        email: $email
        cpf: $cpf
      )
    }
  `;
  return await client.mutate({
    mutation: EnrichCustomersByFilter,
    variables: data,
    fetchPolicy: "no-cache",
  });
}

export async function importCustomerCSV(data) {
  const gq = gql`
    mutation ($businessId: Int!, $file: String!) {
      ImportCustomerCsv(businessId: $businessId, file: $file)
    }
  `;
  return await client.mutate({
    mutation: gq,
    variables: data,
    fetchPolicy: "no-cache",
  });
}
