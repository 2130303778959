import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import {addSpaceBetweenSlashes} from "../../../../services/utils.jsx";


function BrandCard({ brandTitle="", className="", ...rest }) {

  return (
    <li className={`gs-brands-list-item ${className}`} {...rest}>
      <span className="gs-brand-list-item-title">{addSpaceBetweenSlashes(brandTitle)}</span>
    </li>
  );
}

BrandCard.propTypes = {
  brandTitle: PropTypes.string,
  className: PropTypes.string,
};


export default BrandCard;
