import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";


function Header({title="", subtitle=""}) {
  return (
	  <header className="gs-brands-header-container">
		  <h3 className="gs-brands-header-title">{title}</h3>
		  <span className="gs-brands-header-subtitle">{subtitle}</span>
	  </header>
  );
}

Header.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

export default Header;
