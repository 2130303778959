import React from 'react';
import { Skeleton } from "@mui/material";

function WelcomeLeftSkeleton() {
  return (
    <div className="welcome-left">
      <Skeleton width="50px" height="20px" className='goodbye-skeleton-text' />
      <Skeleton width="258px" height="40px" className='goodbye-skeleton-text' />
      <Skeleton width="260px" height="20px" className='goodbye-skeleton-text' />
    </div>
  );
}

export default WelcomeLeftSkeleton;
