import React from 'react';
import { Skeleton } from "@mui/material";


function WelcomeSkeleton() {
  return (
    <div className="page-logo-image">
      <Skeleton variant="circular" width="160px" height="160px" />
    </div>
  );
}

export default WelcomeSkeleton;
