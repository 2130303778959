import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";


function PromotionDisabled({ businessGroupName }) {
  return (
    <section className="gs-promotion-disabled">
        <h2 className="promotion-disabled__title">Ops!</h2>
        <p className="promotion-disabled__text">Estamos sem promoções ativas no momento.</p>
      <p className="promotion-disabled__text">Continue acompanhando para acessar esse novo benefício do <em>{businessGroupName}</em> nas suas próximas visitas!</p>
    </section>
  );
}

PromotionDisabled.propTypes = {
  businessGroupName: PropTypes.string.isRequired,
};
export default PromotionDisabled;
