import {client, gql} from "../config";
import {companySelected} from "../../services/auth";

export async function getBusiness(data) {
  const businessQuery = gql`
      query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
          Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
              data {
                  id
                  name
                  cnpj
                  facebook
                  instagram
                  siteUrl
                  createdAt
                  city {
                      name
                      ibge
                  }
                  owner {
                      name
                  }
                  objectives {
                      description
                      id
                  }
                  operatingAreas {
                      name
                      id
                  }
                  products {
                      id
                  }
                  biudConnect
                  isDataSharingEnabled
                  logo
                  RFV
                  facebookPage {
                      isUserTokenExpired
                      isPageTokenExpired
                      lastDataUpdate
                      facebookPageId
                      pageToken
                      businessManagerId
                      adAccountId
                      businessManagers {
                          id
                          name
                          adAccounts {
                              id
                              name
                          }
                      }
                  }
                  currentAddress {
                      postalCode
                      street
                      district
                      complement
                      number
                      city {
                          ibge
                      }
                  }
                  businessFiscalCodes {
                      id
                      description
                      initial
                      final
                  }
                  lat
                  long
              }
          }
      }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data[0],
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getBusinessNoProducts(data) {
  const businessQuery = gql`
      query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
          Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
              data {
                  id
                  name
                  cnpj
                  facebook
                  instagram
                  siteUrl
                  createdAt
                  city {
                      name
                      ibge
                  }
                  owner {
                      name
                  }
                  objectives {
                      description
                      id
                  }
                  operatingAreas {
                      name
                      id
                  }
                  biudConnect
                  isDataSharingEnabled
                  logo
                  RFV
                  facebookPage {
                      isUserTokenExpired
                      isPageTokenExpired
                      lastDataUpdate
                      facebookPageId
                      pageToken
                      businessManagerId
                      adAccountId
                      businessManagers {
                          id
                          name
                          adAccounts {
                              id
                              name
                          }
                      }
                  }
                  currentAddress {
                      postalCode
                      street
                      district
                      complement
                      number
                      city {
                          ibge
                      }
                  }
                  businessFiscalCodes {
                      id
                      description
                      initial
                      final
                  }
                  lat
                  long
              }
          }
      }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data[0],
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getBusinessWithFacebookData(data) {
  const businessQuery = gql`
    query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
      Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
        data {
          id
          name
          cnpj
          facebook
          instagram
          siteUrl
          createdAt
          city {
            name
            ibge
          }
          owner {
            name
          }
          objectives {
            description
            id
          }
          operatingAreas {
            name
            id
          }
          products {
            id
          }
          biudConnect
          isDataSharingEnabled
          logo
          RFV
          facebookPage {
            isUserTokenExpired
            isPageTokenExpired
            lastDataUpdate
            facebookPageId
            pageToken
            businessManagerId
            adAccountId
            businessManagers {
              id
              name
              adAccounts {
                id
                name
              }
            }
          }
          currentAddress {
            postalCode
            street
            district
            complement
            number
            city {
              ibge
            }
          }
          businessFiscalCodes {
            id
            description
            initial
            final
          }
          lat
          long
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data[0],
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getBusinessFacebookData(data) {
  const businessQuery = gql`
    query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
      Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
        data {
          facebookPage {
            facebookPageId
            pageToken
            businessManagerId
            adAccountId
            businessManagers {
              id
              name
              adAccounts {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getDataSharingEnabled() {
  const businessQuery = gql`
    query {
      Business(dataSharingEnabled: true, active: true) {
        data {
          id
          name
          cnpj
          logo
          isDataSharingEnabled
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getBusinessWithOwner(data) {
  const getBusinessWithOwnerQuery = gql`
    query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean) {
      Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled) {
        data {
          id
          name
          cnpj
          logo
          biudConnect
          owner {
            name
          }
        }
      }
    }
  `;

  const business = await client.query({
    query: getBusinessWithOwnerQuery,
    variables: data,
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function isDataSharingEnabled(data) {
  const businessQuery = gql`
    query Business($id: Int) {
      Business(id: $id) {
        data {
          isDataSharingEnabled
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function isBiudConnectInstalled(data) {
  const biudConnectInstalledQuery = gql`
    query Business($id: Int) {
      Business(id: $id) {
        data {
          biudConnect
        }
      }
    }
  `;

  const business = await client.query({
    query: biudConnectInstalledQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getBusinessPage(data) {
  const businessPageQuery = gql`
      query ($id: Int) {
          Business(id: $id) {
              data {
                  name
                  page {
                      id
                      url
                      content
                      logo
                      qrCode
                      imgCupom
                      actions {
                          id
                          url
                          label
                      }
                  }
                  group {
                      id
                      name
                  }
              }
          }
      }
  `;

  const businessPage = await client.query({
    query: businessPageQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await businessPage.data.Business.data;
  return {Business};
}

export async function getBusinessGoodbyPage(data) {
  const businessPageQuery = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          name
          goodbyePage {
            id
            url
            content
            logo
            qrCode
            reasons {
              id
              label
            }
          }
        }
      }
    }
  `;

  const businessPage = await client.query({
    query: businessPageQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await businessPage.data.Business.data;
  return {Business};
}

export async function getBusinessByCnpj(cnpj) {
  const businessCnpjQuery = gql`
    query ($cnpj: String!) {
      Business(cnpj: $cnpj) {
        data {
          id
        }
      }
    }
  `;

  const business = await client.query({
    query: businessCnpjQuery,
    variables: {cnpj: cnpj},
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};
}

export async function getIsDataEnrichmentEnabled(id) {
  const isDataEnrichmentEnabled = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          isDataEnrichmentEnabled
        }
      }
    }
  `;

  const business = await client.query({
    query: isDataEnrichmentEnabled,
    variables: {id: id},
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {data: {Business}};
}

export async function getBusinessById(id) {
  const businessById = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          name
          logo
        }
      }
    }
  `;

  const business = await client.query({
    query: businessById,
    variables: {id: id},
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {Business};

}

export async function getBusinessHasCertified(id) {
  const businessById = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          certificates {
            id
            error
            processedAt
          }
          hasCertificate
        }
      }
    }
  `;

  const business = await client.query({
    query: businessById,
    variables: {id: id},
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return {data: {Business}};
}

export async function getBusinessUsers(id) {
  const businessById = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          users {
            type
            user {
              id
              name
              email
              phone
              cpf
              enabled
            }
          }
        }
      }
    }
  `;

  const business = await client.query({
    query: businessById,
    variables: {id: id},
    fetchPolicy: "no-cache",
  });
  const Business = business.data.Business.data;
  return {data: {Business}};
}

export function getSchedulesBusinessCertProcess(businessId) {
  const businessById = gql`
    query ($businessId: Int) {
      ScheduleBusinessCertificateProcess(business: $businessId) {
        startedAt
        endedAt
      }
    }
  `;

  return client.query({
    query: businessById,
    variables: {businessId: businessId},
    fetchPolicy: "no-cache",
  });
}

export async function getBusinessLogs() {
  const businessById = gql`
    query ($id: Int) {
      Business(id: $id) {
        data {
          logs {
            message
            user {
              id
              name
              email
            }
            createdAt
            metadata {
              key
              value
            }
          }
        }
      }
    }
  `;

  const business = await client.query({
    query: businessById,
    variables: {id: companySelected()},
    fetchPolicy: "no-cache",
  });

  const Business = business.data.Business.data;
  return {data: {Business}};
}

export async function leadsDataFranchise(data) {
  const businessQuery = gql`
    query Business($id: Int) {
      Business(id: $id) {
        data {
          biudDataRemainingCredits
          biudDataCredits
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data,
  });

  const Business = await business.data.Business.data;
  return {Business};
}
