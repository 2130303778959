import React from 'react';
import { Skeleton } from '@mui/material';
import './styles.css'


function GoodbyeSkeleton() {
  return (
    <div className="flex goodbye-content">
      <div className="goodbye-center">
        <div className="page-logo-image">
          <Skeleton variant="circular" width="160px" height="160px" />
        </div>
        <div>
          <Skeleton width="250px" height="20px" className='goodbye-skeleton-text' />
          <Skeleton width="650px" height="20px" className='goodbye-skeleton-text' />
          <Skeleton width="400px" height="20px" className='goodbye-skeleton-text' />
        </div>
        <form className="form goodbye-form">
          <Skeleton width="650.31px" height="42.8px" variant="rounded" className='goodbye-skeleton-btns' />
          <Skeleton width="650.31px" height="42.8px" variant="rounded" className='goodbye-skeleton-btns' />
          <Skeleton width="650.31px" height="42.8px" variant="rounded" className='goodbye-skeleton-btns' />
        </form>
      </div>
    </div>
  );
}

export default GoodbyeSkeleton;
