import React, { useState, useEffect } from "react";
import "./styles.css";
import { imgAlert } from "../../assets/imgs";
import { useParams } from "react-router-dom";
import GoodbyeSkeleton from "./GoodbyeSkeleton";
import { CircularProgress } from "@mui/material";
import imgPatch from "../../assets/imgs/path.svg";
import { decodeBase64 } from "../../services/utils";
import { getUrlImagesFB } from "../../services/firebase";
import imgBusiness from "../../assets/imgs/business.svg";
import BiudButton from "../../components/BiudButton/index.jsx";
import { RegisterCustomerOptOut } from "../../graphql/Mutation/customer";
import {
  getBusinessGoodbyePage,
  getBusinessGroupGoodbyePage,
  getUserAlreadyRegistered, getUserAlreadyRegisteredInGroup
} from "../../services/apis/biud/fetchs.js";

export default function Goodbye() {
  const params = useParams();
  const [data, setData] = useState({
    image: "",
    content: "",
  });

  const [customer, setCustomer] = useState(false);

  const [buttons, setButtons] = useState([]);
  const [success, setSuccess] = useState(undefined);
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);

  const isNetworkPage = () => {
    const location = window.location;
    return location.pathname.split("/").includes("group");
  }

  useEffect(() => {
    const getResponse = async () => {
      const id = parseInt(params.id);
      if (isNetworkPage()) {
        return await getBusinessGroupGoodbyePage({ businessGroupId: id });
      }
      return await getBusinessGoodbyePage({ businessId: id });
    }
    async function getInfoBusinessPage() {
      setLoading(true);
      try {
        const id = parseInt(params.id);
        const res = await getResponse();
        const group = res?.data?.group;
        const page = res?.data?.page;

        if (!group || !page) {
          throw new Error("Group or page not found");
        }

        console.log("res", res);

        setBusinessName(group.name);
        const image = await getUrlImagesFB(page.logo);
        setButtons(page.reasons);
        setData({
          image: image,
          content: page.content,
        });
        const cpf = decodeBase64(params.cpf).match(/\d/g).join('');
        const customerResponse = isNetworkPage()
          ? await getUserAlreadyRegisteredInGroup({businessGroupId: id, cpf})
          : await getUserAlreadyRegistered({ businessId: id, cpf });

        setCustomer(customerResponse.customer)
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
  }
  getInfoBusinessPage();
}, [params.id, params.cpf]);


  const registerCheck = async (reasonId) => {
    if (customer) {
      try {
        setRegisterLoading(true);
        let formData = {};
        formData.businessId = isNetworkPage() ? null : params.id;
        formData.groupId= isNetworkPage() ? params.id : null;
        formData.customerId = customer.id;
        formData.reasonId = reasonId;
        await RegisterCustomerOptOut(formData);
        setSuccess(true);
      } catch (err) {
        console.log("Func registerCheck error: ", err)
      } finally {
        setRegisterLoading(false);
      }
    } else {
      setSuccess(false);
    }
  }

  const handleClick = (e, reasonId) => {
    e.preventDefault();
    registerCheck(reasonId);
  }

  const pageContent = (
    <>
    {!loading
      ? (
        <div className="flex goodbye-content">
          <div className="goodbye-center goodbye-form-container">
            <div className={`page-logo-image`} style={{ display: `${loading ? 'none': 'block'}`}}>
              <img src={data.image} alt="Abandono de lista" />
            </div>
            <div>{data.content}</div>
            <form className="form goodbye-form goodbye-buttons-container">
              {!registerLoading ? buttons.map((buttonData, index) => (
                <BiudButton
                  buttonType="biud"
                  size="lg"
                  key={`button-page-${index}-31`}
                  style={{width: "100%", padding: "8px 16px", minWidth: "max-content" }}
                  onClick={(e) => handleClick(e, buttonData.id) }
                >
                  {buttonData.label}
                </BiudButton>
              )) : <CircularProgress size={60} style={{ marginTop: "16px", color: "#d81656" }} />}
            </form>
          </div>
        </div>
      ) : <GoodbyeSkeleton />
    }
    </>
  );

  const successContent = (
    <div className="goodbye-success">
      <img
        alt={"Bem-vindo"}
        className="align-start email-business-backimg"
        src={imgPatch}
      />
      <img
        alt={customer?.name || "business"}
        className="align-start email-business-img"
        src={imgBusiness}
      />
      <h2>Foi bom enquanto durou, {customer?.name?.split(" ")[0]}!</h2>
      <h3>
        Você <strong>não receberá mais</strong> nossas melhores ofertas.
      </h3>
    </div>
  );

  const failContent = (
    <div className="goodbye-fail">
      <img
        alt="business"
        className="align-start email-business-img"
        src={imgAlert}
      />
      <h2>O cliente não foi encontrado na base de dados da {businessName}!</h2>
    </div>
  );

  return (
    <>
      <div className="goodbye-landing-page">{success === undefined ? pageContent : success === true ? successContent : failContent}</div>
      <footer className="goodbye-footer" />
    </>
  );
}

export const generateUrlOptOut = (id, cpf, isNetworkPage) => import.meta.env.VITE_CUSTOMERS_URL + (isNetworkPage() ? "goodbye/group/" : "goodbye/") + id + "/" + btoa(cpf);
