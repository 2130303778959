import React, { useState, useEffect } from "react";
import "./styles.css";
import moment from "moment";
import WelcomeComponents from "./components";
import { external } from "../../services/linkHelper";
import OptinForm from "./components/OptinForm/index.jsx";
import { getUrlImagesFB } from "../../services/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomers } from "../../graphql/Query/customers";
import { RegisterCustomerOptIn } from "../../graphql/Mutation/customer";
import { validarCPF, removerCaracteresEspeciais } from "../../services/utils";
import {getBusinessPage, getUserAlreadyRegistered} from "../../services/apis/biud/fetchs.js";

export default function Welcome() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    image: "",
    content: "",
  });

  const navigate = useNavigate();
  const [invalidCPF, setInvalidCPF] = useState(false);
  const invalidPhone = false;
  // const [buttons, setButtons] = useState([]);
  const [customerData, setCustomerData] = useState({
    name: "",
    cpf: "",
    email: null,
    phone: "",
    bornAt: null,
    gender: "fem",
    genderOther: null,
    useTerms: false,
  });

  const cpfUnmasked = removerCaracteresEspeciais(customerData.cpf);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [loadingForm, setloadingForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pageContent, setPageContent] = useState(<></>);

  function isCPFValid() {
    const cpf = document.getElementsByName("cpf")[0] || {
      value: customerData.cpf,
    };
    const cpfUnmasked = removerCaracteresEspeciais(cpf.value ?? "");
    if (cpfUnmasked.length === 0) {
      setInvalidCPF(true);
      cpf.classList?.add("input-required-invalid");
      return false;
    } else if (cpfUnmasked.length === 11) {
      cpf.classList?.remove("input-required-invalid");
      setInvalidCPF(!validarCPF(cpfUnmasked) && cpfUnmasked !== "99999999999");
      return validarCPF(cpfUnmasked);
    }
  }

  function isNameValid() {
    let name = document.getElementsByName("name")[0];
    if (name.value.length > 2) {
      name.classList.remove("input-required-invalid");
      setErrorMsg("")
      return true;
    }
    name.classList.add("input-required-invalid");
    setErrorMsg("nome inválido")
    return false;
  }

  function isPhoneValid() {
    let phone = document.getElementsByName("phone")[0];
    const phoneUnmasked = removerCaracteresEspeciais(phone.value ?? "");
    if (phoneUnmasked.length === 11) {
      phone.classList.remove("input-required-invalid");
      setErrorMsg("")
      return true;
    }
    phone.classList.add("input-required-invalid");
    setErrorMsg("celular inválido")
    return false;
  }

  function isEmailValid() {
    const emailEl = document.getElementsByName("email")[0];
    const emailRegex =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    const valid = emailRegex.test(emailEl.value ?? "");
    if (valid) {
      emailEl.classList.remove("input-required-invalid");
      return true;
    }
    emailEl.classList.add("input-required-invalid");
    return false;
  }

  const registerCheck = async () => {
    const formData = {};
    let isFormValid = isNameValid() && isCPFValid() && isPhoneValid();

    const inputList = Array.prototype.slice.call(
      document.getElementsByTagName("input")
    );

    inputList.forEach(function (elem) {
      if (!elem.value && (elem.name === "phone" || elem.name === "name" || (customerData.gender === "other" && elem.name === "genderOther"))) {
        elem.classList.add("input-required-invalid");
        isFormValid = false;
      } else {
        formData[elem.name] = elem.value;
        if (
          elem.name !== "phone" &&
          elem.name !== "cpf"
        ) {
          elem.classList.remove("input-required-invalid");
        }
      }
    });

    if (isFormValid) {
      try {
        setloadingForm(true);

        formData.businessId = parseInt(params.id);
        formData.cpf = customerData.cpf;
        formData.bornAt = customerData?.bornAt
          ? moment(customerData?.bornAt).format("YYYY-MM-DD")
          : null;
        formData.registeredByCustomer = true;

        const res = await RegisterCustomerOptIn(formData);

        if (res.data.RegisterCustomerOptIn) {
          navigate(`${external.SUCCESS}/${params.id}`, {
            state: {
              cpf: customerData.cpf,
              name: customerData?.name || "",
              isNewOptIn: true,
            },
          });
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setloadingForm(false);
      }
    }
  }

  const changeCustomerData = (e, isCheckBox = false) => {
    let tempData = { ...customerData };
    tempData[e.target.name] = !isCheckBox ? e.target.value : e.target.checked;
    setCustomerData(tempData);
  }

  useEffect(() => {
    const getInfoBusinessPage = async () => {
      setLoading(true);
      try {
        const id = parseInt(params.id);
        const res = await getBusinessPage({ businessId: id });

        if (!res?.data?.page) return;

        const image = await getUrlImagesFB(res.data.page?.logo);
        setData({
          image,
          content: res.data.page?.content,
        });
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };
    getInfoBusinessPage();
  }, [params.id]);

  const checkCustomerExists = async (cpf) => {
    try {
      const res = await getUserAlreadyRegistered({ businessId: parseInt(params.id), cpf: cpfUnmasked });
      if (res?.isCustomerAlreadyOptIn) {
        navigate(`${external.SUCCESS}/${params.id}`, {
          state: {
            cpf: customerData.cpf,
            name: customerData?.name || "",
            isNewOptIn: false,
          },
        });
      } else {
        setShowRegisterForm(true);
      }
    } catch(err) {
      console.error(err.message);
    }
  }

  const findCustomerByCpf = async (cpf) => {
    try {
      setloadingForm(true);
      await checkCustomerExists(cpf);
    } catch (err) {
      console.error(err.message);
    } finally {
      setloadingForm(false);
    }
  };

  useEffect(() => {
    const cpf = removerCaracteresEspeciais(customerData.cpf);
    if (cpf.length < 11) return;
    if (!isCPFValid() && cpfUnmasked !== "99999999999") return;

    findCustomerByCpf(cpf);

  }, [customerData.cpf]);

  const handleFormContent = () => {
    if (loading) return <></>;

    if (showRegisterForm) {
        return <WelcomeComponents.DefaultOptinForm
          invalidPhone={invalidPhone}
          changeCustomerData={changeCustomerData}
          setCustomerData={setCustomerData}
          customerData={customerData}
        />
    }

    return (
      <WelcomeComponents.CpfForm
        setInvalidCPF={setInvalidCPF}
        loadingForm={loadingForm}
        customerData={customerData}
        changeCustomerData={changeCustomerData}
        invalidCPF={invalidCPF}
      />
    );
  };

  return (
    <>
      <div className="welcome-page">
        <>
          <WelcomeComponents.Logo data={data} loading={loading} />
          <div className="welcome-content">
            <div className="welcome-right">
              {!loading ? <div>{data.content}</div> : <WelcomeComponents.WelcomeLeftSkeleton />}
              <OptinForm
                customerData={customerData}
                registerCheck={registerCheck}
                showRegisterForm={showRegisterForm}
                handleFormContent={handleFormContent}
                loadingForm={loadingForm}
                loading={loading}
                isGeneralGroup={false}
              />
            </div>
          </div>
        </>
      </div>
      <footer className="welcome-footer" />
    </>
  )
}
