import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";


function GeneralTermsOfUse({ containerProps }) {
  return (
	  <div className="font-medium text-center" {...containerProps}>
      <span className="color-gray-strong">
        Ao digitar seu cpf você declara estar de acordo em receber ofertas de lojistas do Outlet Premium,
        conforme os <a
        className="link-not-decored button-privacy-link color-blue"
        target="_blank"
        href={"https://gen.com.br/transparencia/termosecondicoes"}
        rel="noopener noreferrer"
      >
          Termos de uso
        </a> e <a
        className="link-not-decored button-privacy-link color-blue"
        target="_blank"
        rel="noopener noreferrer"
        href={"https://gen.com.br/transparencia/privacidade"}
      >
          Política de privacidade
        </a>
      </span>
	  </div>
  );
}

GeneralTermsOfUse.propTypes = {
  containerProps: PropTypes.object,
};

export default GeneralTermsOfUse;
