import PropTypes from "prop-types";
import React from "react";
import "./styles.css";

export default function OfflineHeader({ image, text }) {
  return (
    <div className="offline-header-container">
      <img src={image} alt="Logotipo" />
      <h1>{text}</h1>
    </div>
  );
}

OfflineHeader.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
