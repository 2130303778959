import md5 from 'md5';
import jwt_decode from "jwt-decode";
import {removeBlipContactInfo} from "./blip";

export const TOKEN_KEY = "app-biud-token";
export const TOKEN_TYPE = "app-biud-token-type";
export const COMPANY_SELECTED = "company-selected";
export const COMPANY_OBJECT = "company-object";
export const GROUP_OBJECT = "group-object";
export const COMPANY_NAME_SELECTED = "company-name-selected";
export const COMPANY_IMAGE_SELECTED = "company-image-selected";
export const BIUD_CONNECT = "biud_connect";
export const CNPJ_CONFLICT = false;
export const IS_DATA_SHARING = "data_sharing";
export const PRODUCT_NOT_REVIEWED = "product_not_reviwed";
export const CONFIG_ACTIVE_TAB = "conf_act_tab";
export const PRODUCT_ACTIVE_TAB = "product_act_tab";
export const isAuthenticated = localStorage.getItem(TOKEN_KEY) !== null;
export const companyIsSelected = localStorage.getItem(COMPANY_SELECTED) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const productNotReviwed = (isProduct) => {
  localStorage.setItem(PRODUCT_NOT_REVIEWED, isProduct);
};

export const companyName = () => {
  return localStorage.getItem(COMPANY_NAME_SELECTED);
};

export const setIsDataSharing = (bool) => {
  localStorage.setItem(IS_DATA_SHARING, bool);
};

export const setConfActiveTab = (activeTab) => {
  localStorage.setItem(CONFIG_ACTIVE_TAB, activeTab);
};

export const setProductActiveTab = (activeTab) => {
  localStorage.setItem(PRODUCT_ACTIVE_TAB, activeTab);
};

export const getConfActiveTab = () => {
  const activeTab = localStorage.getItem(CONFIG_ACTIVE_TAB);
  return activeTab ? parseInt(activeTab) : 1;
};

export const getProductActiveTab = () => {
  const activeTab = localStorage.getItem(PRODUCT_ACTIVE_TAB);
  return activeTab ? parseInt(activeTab) : 1;
};

export const isDataSharing = () => {
  return localStorage.getItem(IS_DATA_SHARING) === "true";
};

export const setCompany = (company) => {
  localStorage.removeItem(COMPANY_OBJECT);
  localStorage.setItem(COMPANY_OBJECT, JSON.stringify(company));
};

export const setGroup = (group) => {
  localStorage.removeItem(GROUP_OBJECT);
  localStorage.setItem(GROUP_OBJECT, JSON.stringify(group));
};

export const selectCompany = (companyId) => {
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.setItem(COMPANY_SELECTED, companyId);
};

export const deleteCompany = () => {
  localStorage.removeItem(COMPANY_SELECTED);
};

export const selectNameCompany = (companyName) => {
  localStorage.removeItem(COMPANY_NAME_SELECTED);
  localStorage.setItem(COMPANY_NAME_SELECTED, companyName);
};

export const selectImageCompany = (companyImage) => {
  localStorage.removeItem(COMPANY_IMAGE_SELECTED);
  // Todo image firebase
  // const res = getUrlImagesFB(companyImage)
  localStorage.setItem(COMPANY_IMAGE_SELECTED, companyImage);
};

export const selectNewCompany = (company) => {
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.setItem(COMPANY_SELECTED, company);
};

export const setCnpjConflict = (bool) => {
  localStorage.setItem(CNPJ_CONFLICT, bool);
};

export const biudConnectSet = (bool) => {
  localStorage.setItem(BIUD_CONNECT, bool);
};

export const isBiudConnect = () => {
  return localStorage.getItem(BIUD_CONNECT) === "true";
};

export const resetGroupSelected = () => {
  // removeBlipContactInfo();
  // localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(COMPANY_NAME_SELECTED);
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.removeItem(COMPANY_OBJECT);
  localStorage.removeItem(COMPANY_IMAGE_SELECTED);
  localStorage.removeItem(GROUP_OBJECT);
  localStorage.removeItem(CONFIG_ACTIVE_TAB);
  localStorage.removeItem(PRODUCT_ACTIVE_TAB);
  // window.location.reload(false);
};

export const logout = () => {
  removeBlipContactInfo();
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(COMPANY_NAME_SELECTED);
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.removeItem(COMPANY_OBJECT);
  localStorage.removeItem(COMPANY_IMAGE_SELECTED);
  localStorage.removeItem(GROUP_OBJECT);
  localStorage.removeItem(CONFIG_ACTIVE_TAB);
  localStorage.removeItem(PRODUCT_ACTIVE_TAB);
  window.location.reload(false);
};

export const companySelected = () => {
  const id = localStorage.getItem(COMPANY_SELECTED);

  return parseInt(id);
};

export const groupSelected = () => {
  return parseInt(getGroup().id);
};

export const companyImageSelected = () => {
  return localStorage.getItem(COMPANY_IMAGE_SELECTED);
};

export const companyImageClear = () => {
  return localStorage.removeItem(COMPANY_IMAGE_SELECTED);
};

export const getCompany = () => JSON.parse(localStorage.getItem(COMPANY_OBJECT));

export const getGroup = () => JSON.parse(localStorage.getItem(GROUP_OBJECT));

export const getGroupName = () => {
  const checkNetworkName = getGroup().name.match(/rede/i);
  const networkName = checkNetworkName ? getGroup().name : `Rede ${getGroup().name}`;
  return networkName;
};

export const getGroupNameWithoutRede = () => {
  const networkName = getGroupName().split(" ").slice(1).join(' ');
  return networkName;
};

export const getGroupList = () => {
  return getGroup()?.businesses.map((item) => ({id: item.id, name: item.name}));
};

export const getBusinessIds = () => {
  return getGroup().businesses.map((biz) => biz.id);
};

export const cnpjConflict = () => {
  return localStorage.getItem(CNPJ_CONFLICT);
};

export const getTokenDecode = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return jwt_decode(token);
};

export const isAdmin = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const decodedToken = jwt_decode(token);
  return decodedToken.roles.includes("ROLE_ADMIN");
};

export const getCurrentUserEmail = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const decodedToken = jwt_decode(token);
  return decodedToken.username;
};

export const addUserType = (type) => {
  const md5Type = md5(type);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.setItem(TOKEN_TYPE, JSON.stringify(md5Type));
};

export const existsUserType = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  return token ? true : false;
};

export const isOwner = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const ownerMd5 = md5('OWNER');
  return token === ownerMd5;
};

export const isWorker = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const workerMd5 = md5('WORKER');
  return token === workerMd5;
};

export const isManager = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const managerMd5 = md5('MANAGER');
  return token === managerMd5;
};

export const isCustomerService = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const customerServiceMd5 = md5('CUSTOMER_SERVICE');
  return token === customerServiceMd5;
};

export const isSeller = () => {
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const sellerMd5 = md5('SELLER');
  return token === sellerMd5;
};

export const isProductNotReviwed = () => {
  return localStorage.getItem(PRODUCT_NOT_REVIEWED);
};

export const isCS = () => {
  //TODO: remove mock - salvar chave isCS no localStorage ("true" ou "false")
  localStorage.setItem("isCS", "true");
  return true;
};
