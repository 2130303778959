import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { FormHelperText } from "@mui/material";
import { removerCaracteresEspeciais } from "../../../../services/utils.jsx";


function CpfForm({ changeCustomerData, invalidCPF, setInvalidCPF, customerData, loadingForm }) {
  return (
	  <>
		  <p className="welcome-form-label">Qual o seu CPF?</p>
		  <InputMask
			  name="cpf"
			  className={`default-input ${invalidCPF ? "input-invalid" : ""}`}
			  onChange={(e) => {
				  if (
					  removerCaracteresEspeciais(customerData.cpf) ===
					  removerCaracteresEspeciais(e.target.value)
				  )
					  return;
				  changeCustomerData(e);
				  setInvalidCPF(false);
			  }}
			  mask="999.999.999-99"
			  placeholder="999.999.999-99"
			  disabled={loadingForm}
		  />
		  {invalidCPF && (
			  <FormHelperText
				  error={true}
				  className="font-medium"
				  style={{ fontFamily: "Outfit, sans-serif" }}
			  >
				  CPF inválido
			  </FormHelperText>
		  )}
		  {loadingForm && <span className="searching-cpf-span">Buscando...</span>}
	  </>
  );
}

CpfForm.propTypes = {
	changeCustomerData: PropTypes.func.isRequired,
	invalidCPF: PropTypes.bool.isRequired,
	setInvalidCPF: PropTypes.func.isRequired,
	customerData: PropTypes.object.isRequired,
	loadingForm: PropTypes.bool.isRequired,
};

export default CpfForm;
