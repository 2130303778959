import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

const config = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseFirestore = firebase.firestore();
export const firebaseStorage = firebase.storage();
export const imagesRefFB = firebaseStorage.ref("images");
export const biudConnectRefFB = firebaseStorage.ref("biudConnect");
export const certifiedRefFB = firebaseStorage.ref("certificates");
export const filesRefFB = firebaseStorage.ref("files");
export const filesReportsRefFB = firebaseStorage.ref("reports");

export const getUrlBiudConnect = () => {
  return biudConnectRefFB.child("biud-collector-install.exe").getDownloadURL();
};

export const getUrlImagesFB = async (image) => {
  return await imagesRefFB.child(image).getDownloadURL();
};

export const getReportDownloadUrl = async (fileName) => {
  return await filesReportsRefFB.child(fileName).getDownloadURL();
};

export const sendImage = async (file, nameImage) => {
  try {
    await imagesRefFB.child(nameImage).put(file);
  } catch (err) {
    console.log(err);
  }
};

export const sendImageSync = (file, nameImage) => {
  return imagesRefFB.child(nameImage).put(file);
};

export const sendCertified = async (file, nameFile) => {
  try {
    await certifiedRefFB.child(nameFile).put(file);
  } catch (err) {
    console.log(err);
  }
};

export const sendFile = async (file, nameFile) => {
  try {
    await filesRefFB.child(nameFile).put(file);
  } catch (err) {
    console.log(err);
  }
};
