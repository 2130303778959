import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import imgBusiness from "../../../../assets/imgs/business.svg";
import WelcomeSkeleton from "../Skeletons/WelcomeSkeleton.jsx";
import { generalLogo } from "../../../../assets/imgs/index.js";


function Logo({ data, loading, imgAlt="Assinar ofertas", imgClassName="business-logo-img", isGeneral = false }) {
	if (loading) return <WelcomeSkeleton />

  return (
	  <div className="page-logo-image">
		  <img
			  src={isGeneral ? generalLogo : (data.image || imgBusiness)}
			  alt={imgAlt}
			  className={imgClassName}
		  />
	  </div>
  );
}

Logo.propTypes = {
	data: PropTypes.shape({
		image: PropTypes.string,
	}),
	loading: PropTypes.bool.isRequired,
	imgAlt: PropTypes.string,
	imgClassName: PropTypes.string,
	isGeneral: PropTypes.bool,
};

export default Logo;
