import React from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import { external } from "../../../../services/linkHelper.js";
import imgBusiness from "../../../../assets/imgs/business.svg";

function DefaultSucessPage({cupomImg, buttons, logo, loading, location, params, customerData, groupBusinessId, hasValidOffer}) {
	const navigate = useNavigate();
  return (
	  <div className="welcome-page">
		  <div className="welcome-success">
			  <div>
				  <img
					  alt="business logo"
					  className="align-start email-business-img business-logo-img"
					  src={logo || imgBusiness}
					  width={"100%"}
					  height={loading ? 160 : 120}
				  />
			  </div>
			  <h1>Você está pronto para aproveitar ofertas incríveis!</h1>
			  <p>
				  Seu cadastro foi concluído. Você não precisa realizá-lo novamente nas
				  próximas compras.
			  </p>

			  {cupomImg && location.state.isNewOptIn && (
				  <div className="div-image-cupom">
					  <img
						  className="image-cupom business-logo-img"
						  alt="Cupom promocional"
						  src={cupomImg}
					  />
				  </div>
			  )}

			  {groupBusinessId !== 1463 && hasValidOffer && (
				  <div
					  className="btn btn-welcome btn-welcome-link link-not-decored"
					  onClick={() => {
						  navigate(`${external.OFFERS}/${params.businessId}`, {
							  state: {
								  cpf: customerData,
								  groupBusinessId: groupBusinessId,
							  },
						  });
					  }}
				  >
					  Ver ofertas
				  </div>
			  )}

			  {buttons?.map((buttonData, index) => (
				  <div key={`btn-welcome-${index}`} className="welcome-right">
					  <form className="form optin">
						  <a
							  key={`button-page-${index}-31`}
							  className="btn btn-welcome btn-welcome-link link-not-decored"
							  href={buttonData.url}
							  target="_blank"
							  rel="noopener noreferrer"
						  >
							  {buttonData.label}
						  </a>
					  </form>
				  </div>
			  ))}
		  </div>
	  </div>
  );
}

DefaultSucessPage.propTypes = {
	cupomImg: PropTypes.string,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			url: PropTypes.string,
		})
	),
	logo: PropTypes.string,
	loading: PropTypes.bool,
	location: PropTypes.shape({
		state: PropTypes.shape({
			isNewOptIn: PropTypes.bool,
		}),
	}),
	params: PropTypes.object,
	customerData: PropTypes.string,
	groupBusinessId: PropTypes.number,
	hasValidOffer: PropTypes.bool,
};

DefaultSucessPage.defaultProps = {};

export default DefaultSucessPage;
