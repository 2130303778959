import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { getUrlImagesFB } from "../../../services/firebase";
import { currency } from "../../../services/utils";
import imgFood from "../../../assets/imgs/nao_disponivel.jpg";

export default function OffersToCustomer({
  image,
  fromPrice,
  toPrice,
  description,
}) {
  const [link, setLink] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImage = async () => {
      try {
        setLoading(true);

        if (image) {
          const res = await getUrlImagesFB(image);
          setLink(res);
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    loadImage();
  }, [image]);

  if (loading)
    return (
      <div className="offer-list-item-loading">
        <CircularProgress size={20} />
      </div>
    );

  return (
    <div className="offer-list-item">
      <div
        className="offer-list-item-image"
        style={{
          backgroundImage: `url('${
            link !== null && link !== undefined ? link : imgFood
          }')`,
        }}
      />

      {toPrice && toPrice > 0 ? (
        <>
          <div className="offer-list-item-price-from">
            de {currency(fromPrice ?? 0)}
          </div>
          <div className="offer-list-item-price-to-wrapper">
            <span className="bold space-x">por</span>
            <div className="offer-list-item-price-to">
              {currency(toPrice ?? 0)}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="offer-list-item-price-to-wrapper">
            {/*<span className="bold space-x">de</span>*/}
            <div className="offer-list-item-price-to">
              {currency(fromPrice ?? 0)}
            </div>
          </div>
        </>
      )}
      <div className="offer-list-item-description">{description ?? "--"}</div>
    </div>
  );
}

OffersToCustomer.propTypes = {
  description: PropTypes.string,
  fromPrice: PropTypes.number,
  image: PropTypes.any,
  toPrice: PropTypes.number,
};
