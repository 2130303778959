import React from 'react';
import { avatarBiud } from '../../assets/imgs';
import "./styles.css";


function NotFound() {
  return (
    <section className="pag-notfound-container">
      <img src={avatarBiud} alt="BIUD" width={"200px"} height={"200px"} />
      <h1>404</h1>
      <h2>Ops! A página que você está procurando não foi encontrada.</h2>
    </section>
  );
}

export default NotFound;