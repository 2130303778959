import generalLeadsApi from "./api.js";

export const saveLead = async ({ cpf, name, email, phone, bornAt, gender , businessGroupId}) => {
	const res = await generalLeadsApi.post(`leads/create`, {
		cpf,
		name,
		email,
		phone,
		bornAt,
		gender,
		businessGroupId
	});
	return res.data;
}

export const saveLeadSegment = async ({ segmentIds, checkInId }) => {
	const res = await generalLeadsApi.post(`leads/check_in/segments/save`, {
		segmentIds,
		checkInId
	});
	return res.data;
}